.product_section {
  /* margin: 2rem 0rem 4rem 2rem;
    width: 100%;
    height: 100%; */
  width: 100%;
  margin-bottom: 10rem;
}
.product_section .heading {
  font-family: 'Intro', sans-serif;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

/*       First Section       */
.product_section_1 {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  gap: 3rem;
}

.product_section_1::-webkit-scrollbar {
  display: none;
}

.product_card_1 {
  display: flex;
  border: none;
  cursor: pointer;
  background: var(--bg-gray-dark);
  flex-basis: 20%;
  width: 20%;
  min-width: 20%;
  height: 14rem;
  border-radius: 12px;
}

.product_card_1:focus {
  outline: none !important;
}
.product_card_1:focus-within {
  outline: none !important;
}

.product_card_1 .product_image {
  width: 35%;
}

.product_card_1 .product_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.product_card_1 .product_content {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 7px;
  padding: 8px 20px;
}
.product_card_1 .product_heading {
  font-weight: 600;
  font-family: 'Metropolis';
  color: var(--white);
}
.product_card_1 .product_description {
  font-weight: 300;
  font-size: 1.2rem;
  color: var(--white);
  margin-bottom: 5px;
}
.product_card_1 .product_price {
  color: var(--text-yellow);
  font-weight: 600;
  font-size: 1.6rem;
  background: var(--black);
  border-radius: 16px;
  text-align: center;
  padding: 7px 3rem;
}

/*          Second Section          */
.product_section_2 {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  gap: 3rem;
}

.product_section_2::-webkit-scrollbar {
  display: none;
}

.product_card_2 {
  display: flex;
  flex-direction: column;
  background: var(--bg-gray-dark);
  flex-basis: 15%;
  width: 15%;
  min-width: 15%;
  height: 27.2rem;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.product_card_2 .product_image {
  flex-basis: 75%;
  height: 75%;
}
.product_card_2 .product_img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 12px;
  padding: 10px 10px 0 10px;
  border-radius: 16px;
}

.product_card_2 .product_content {
  width: 100%;
  flex-basis: 27%;
  height: 27%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  padding: 3px 20px 5px 20px;
}

.product_card_2 .product_heading {
  font-weight: 600;
  font-size: 16px;
  font-family: 'Metropolis';
  line-height: 16px;
  color: var(--white);
}
.product_description_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.product_card_2 .product_description {
  font-weight: 300;
  font-size: 16px;
  font-family: 'Metropolis';
  color: var(--white);
}
.product_card_2 .product_price {
  color: var(--text-yellow);
  font-weight: 600;
  font-size: 15px;
  font-family: 'Metropolis';
  background: var(--black);
  border-radius: 16px;
  text-align: center;
  padding: 3px 10px;
}

/*          Third Section          */
.product_section_3 {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  gap: 3rem;
  padding-bottom: 3rem;
}

.product_section_3::-webkit-scrollbar {
  display: none;
}

.product_card_3 {
  display: flex;
  background: url('../assets/images/card_bg.png'), var(--bg-black) center no-repeat;
  box-shadow: 0px 0px 40px rgba(30, 30, 30, 0.28);
  border: 1px solid #283142;
  flex-basis: 28%;
  width: 28%;
  min-width: 28%;
  height: 26rem;
  border-radius: 1rem;
  cursor: pointer;
}

.product_card_3 .product_image {
  width: 45%;
}

.product_card_3 .product_img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.product_card_3 .product_content {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  padding: 10px 20px;
}
.product_card_3 .product_heading {
  font-weight: 700;
  font-size: 2.4rem;
  font-family: 'Metropolis';
  color: var(--white);
}
.product_card_3 .product_description {
  font-weight: 300;
  font-size: 1.4rem;
  color: var(--white);
  margin-bottom: 5px;
}
.product_card_3 .product_details {
  color: var(--text-yellow-2);
  /* text-decoration: underline; */
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid var(--text-yellow-2);
  font-weight: 300;
  font-size: 1.4rem;
  text-align: center;
}

/*          Mobile Section          */
.product_section_mobile {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
  gap: 4%;
}

.product_section_mobile::-webkit-scrollbar {
  display: none;
}

.product_card_mobile {
  display: flex;
  flex-direction: column;
  background: transparent;
  flex-basis: 48%;
  width: 48%;
  min-width: 48%;
  height: 35rem;
  position: relative;
  cursor: pointer;
}

.product_card_mobile .product_image {
  flex-basis: 75%;
  height: 75%;
}
.product_card_mobile .product_img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.product_card_mobile .product_content {
  width: 100%;
  flex-basis: 25%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  padding: 3px 5px;
}

.product_card_mobile .product_heading {
  font-weight: 600;
  font-size: 14.5px;
  font-family: 'Metropolis';
  color: var(--text-gray-3);
}
.product_description_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.product_card_mobile .product_description {
  font-weight: 300;
  font-size: 8px;
  font-family: 'Intro' !important;
  color: var(--text-gray-2);
}
.product_card_mobile .product_price {
  color: var(--text-gray-2);
  font-weight: 600;
  font-size: 14.5px;
  font-family: 'Metropolis';
  padding: 3px 0;
}

@media screen and (max-width: 500px) {
  .product_section_mobile {
    overflow-x: hidden;
    flex-wrap: wrap;
  }
  .product_section {
    margin-bottom: 4rem;
  }
}