.home {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding-inline: 2rem;
  flex-direction: column;
  position: relative;
  /* background-color: #000; */
}
.landingImageDiv {
  position: absolute;
  height: 100vh;
  width: 100%;
}
.landingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home_start {
  width: 100%;
  margin: 0px 10px;
  padding: 1.7rem 0px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  z-index: 5;
}

.verify_url {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #000;
  align-items: center;
  justify-content: center;
}

.verify_url .heading {
  color: #fff;
  font-size: 4rem;
  letter-spacing: 2px;
  font-weight: 500;
  text-align: center;
  font-family: sans-serif !important;
  padding-bottom: 2rem;
}

.verify_url .description {
  color: #fff;
  font-size: 2.5rem;
  text-align: center;
  font-family: sans-serif !important;
  padding-bottom: 1rem;
  font-weight: 300;
}

.verify_url .logo {
  background: -webkit-linear-gradient(rgb(128, 54, 165), #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  font-size: 3rem;
  text-align: center;
  font-family: sans-serif !important;
}
