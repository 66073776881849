.signin_phone {
  height: 100%;
  width: 100%;
  /* background-color: var(--black); */
  color: #fff;
  display: flex;
  justify-content: center;
}

.signin_content {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.otp_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}
.otp_container .otp_input {
  width: 6rem;
}
.otp_label {
  color: var(--text-white);
  font-size: 3rem;
  text-align: center;
}
.otp_container .otp_input input {
  width: 100% !important;
  height: 5rem;
  margin-inline: 5px;
  font-size: 2.2rem;
}
.otp_container input {
  outline: none;
  width: 100%;
}

.signin_phone .input_field {
  width: 100%;
  text-align: center;
  padding-inline: 3rem;
}
.signin_phone .input_field input {
  width: 90%;
  padding: 1.2rem 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1.8rem;
}
.signin_phone .input_field input:focus {
  outline: none;
}
.phone_input .PhoneInputCountryIcon {
  width: 5rem;
  height: 5rem;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none !important;
}
.PhoneInputCountrySelectArrow {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  margin-left: 5px;
}
.next_btn:disabled {
  color: var(--text-gray-5);
}
.next_btn {
  font-size: 2.2rem;
  padding: 1rem 2.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
  border: none;
  cursor: pointer;
  background: #9e9e9e;
  color: var(--text-white);
}
