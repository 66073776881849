.divButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submitButton {
  width: 14rem;
}

.alertInputContainer {
  display: flex;
}

.textAlertError {
  font-size: 12px;
  color: red;
  font-weight: 400;
}

.calendar {
  margin-left: 10px;
}

.textAlertSuccess {
  font-size: 14px;
  color: #a6d96a;
  font-weight: 600;
  margin-top: 15px;
}
