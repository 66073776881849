.product_preview {
  width: 100%;
  height: 100%;
  padding: 2rem 6%;
  position: relative;
}

.product_preview_wrapper {
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  overflow-y: auto;
  align-items: center;
}
.product_preview_wrapper::-webkit-scrollbar {
  display: none;
}

.preview_close {
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  border: none;
  background: transparent;
  top: 1%;
  cursor: pointer;
}

.preview_close_img {
  width: 3.6rem;
}

/*          Carousel                */
.carousel_container {
  width: 100%;
}
.react-multi-carousel-item.react-multi-carousel-item--active:nth-child(2) .card_detail {
  background: #fff !important;
}
/*          Card Details            */
.card_detail {
  display: flex;
  flex-direction: column;
  background: var(--bg-gray-dark);
  flex-basis: 30%;
  width: 30%;
  min-width: 30%;
  height: 60%;
  margin-inline: 2.4rem;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
}
/* .card_detail-active {
  margin-right: 50rem;
} */
.card_detail:hover {
  height: 70%;
  flex-basis: 35%;
  width: 35%;
  min-width: 35%;
  /* transform: scale(1.2); */
}
.card_detail .product_image {
  flex-basis: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.card_detail .product_img {
  width: 95%;
  max-width: 100%;
  height: 95%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 16px;
  /* padding: 2rem 2rem 0 2rem; */
}

.card_detail .product_content {
  width: 100%;
  flex-basis: 30%;
  height: 30%;
  gap: 3px;
  padding: 4px 2rem 2rem 2rem;
}

.card_detail .product_content_top {
  width: 100%;
  flex-basis: 30%;
  height: 30%;
  gap: 3px;
  padding: 4px 2rem 2rem 2rem;
}

.card_detail .product_heading {
  font-family: 'Metropolis-Bold';
  font-weight: 600;
  font-size: 14.5px;
  color: var(--white);
  text-align: center;
  margin-bottom: 7px;
}
.card_detail .product_description {
  font-family: 'Metropolis';
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  text-align: center;
  /* margin-bottom: 7px; */
}
.product_price_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.card_detail .product_price {
  font-family: 'Metropolis';
  color: var(--text-yellow);
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  background: var(--white);
  border-radius: 16px;
  text-align: center;
  padding: 0.5rem 2rem;
}

.card_detail .product_share {
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(255, 255, 255, 0.53);
  color: rgba(255, 255, 255, 0.53); */
  cursor: pointer;
  /* padding: 1rem 2rem; */
  font-size: 1.4rem;
  padding-top: 10px;
  /* border-radius: 2rem; */
}

@media screen and (max-width: 500px) {
  .card_detail {
    height: 95% !important;
    background-color: transparent;
  }
  /* .preview_close {
    bottom: 70%;
    right: 6%;
    position: absolute;
    z-index: 1000;
  } */
  .product_preview {
    background: black;
    padding: 2rem 1rem;
  }
  .product_preview_wrapper {
    padding-top: 5rem;
  }
  .card_detail .product_image {
    height: 80%;
    flex-basis: 80%;
  }
  .card_detail .product_img {
    border: 4px solid #DEAE04;
    padding: 4px;
    width: 260px;
    height: 430px;
    border-radius: 0;
  }
  .card_detail .product_content {
    flex-direction: column;
    align-items: center;
    display: flex;
    padding: 1rem 0.5em;
    height: 20%;
    flex-basis: 20%;
  }
  .card_detail .product_content_top {
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 20%;
    flex-basis: 20%;
  }
  .card_detail .product_heading {
    width: 50%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .card_detail .product_description {
    text-align: center;
    /* margin-bottom: 7px; */
  }
  .card_detail .product_price {
    color: var(--text-gray-2);
    font-weight: 500;
    font-size: 16px;
    background: none;
    border-radius: 0;
    text-align: left;
  }
}
