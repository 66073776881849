/* Importing all CSS files */
@import url('https://fonts.googleapis.com/css2?family=Georama:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/intro-book-alt');
/*    Import CSS Files    */
@import url('./Header.css');
@import url('./ProductSection.css');
@import url('./Home.css');
@import url('./Menu.css');
@import url('./Products.css');
@import url('./ProductPreview.css');
@import url('./SignInPhone.css');
@import url('./Responsiveness.css');
@import url('./dark-theme.css');
@import url('./layout.css');
@import url('./light-theme.css');
@import url('./Delivery.css');

/*    Import Font Family    */
@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/helveticaneue/Helvetica\ Neue\ W01\ 66\ Medium\ It.ttf') format('woff');
}
@font-face {
  font-family: 'Agrozza';
  src: url('../assets/fonts/agrozza-demo/AgrozzaDemoRegular-test.ttf') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-ExtraBold.otf') format('woff');
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Georama';
  src: url('../assets/fonts/Georama/Georama-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'Gilroy-Italic';
  src: url('../assets/fonts/Gilroy-Light.otf') format('woff');
}

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../assets/fonts/Metropolis-Bold.otf') format('woff');
}

@font-face {
  font-family: 'Intro';
  src: url('../assets/fonts/Intro/IntroDemoBlackCaps.otf') format('woff');
}

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

.app::-webkit-scrollbar {
  display: none;
}

.MuiDialogContent-root.MuiDialogContent-dividers.css-1t4vnk2-MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}

/* Primary Variables */
:root {
  --primary: 'green';
  --text-color: #fff;
  --text-gray-1: #646464;
  --text-gray-2: #b4b4b4;
  --text-gray-3: #e6e6e6;
  --text-gray-4: #858585;
  --text-gray-5: #777777;
  --text-yellow: #deae04;
  --text-yellow-2: #c9a885;
  --border-gray-1: #646464;
  --border-gray-light: rgba(255, 255, 255, 0.1);
  --bg-gray-1: #1e1e1e;
  --bg-gray-dark: #283142;
  --bg-black: #010204;
  --black: #000;
  --white: #fff;
}

html {
  font-size: 62.5%;
}
body {
  font-family: 'Georama', sans-serif;
}

.app {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.text_ellipsis_1 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_ellipsis_2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*    =====  Scroll Bar Design   =====    */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(36, 34, 34);
  outline: 1px solid rgb(87, 88, 88);
}

.Toastify__toast {
  font-size: 1.2rem;
}
/* ===================     Medi Queries    ===========================   */

/*   Laptop   */
@media screen and (max-width: 1367px) {
  html {
    font-size: 50%;
  }
}

/*   Tablet   */
@media screen and (max-width: 1100px) {
  html {
    font-size: 45%;
  }
}

/*   Tablet Medium   */
@media screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

/*   Mobile Large   */
@media screen and (max-width: 500px) {
  html {
    font-size: 50%;
  }
  body {
    font-family: 'Helvetica', Fallback, sans-serif;
  }
}

/*   Mobile Small   */
@media screen and (max-width: 400px) {
  html {
    font-size: 50%;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #6f48eb;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: #646269;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}
.react-calendar__tile--hasActive {
  background: #6f48eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #bfb3e8;
  color: #6f48eb;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
}
